@import "src/styles/theme";

.c-Button {
  @include all-caps;
  @include interactive;

  -webkit-appearance: none;
  display: inline-flex;
  align-items: stretch;
  padding: 0;
  border: 1px solid transparent;
  background-color: transparent;
  color: currentColor;
  font-size: font-size-rem(-2);
  line-height: 1;
  vertical-align: middle;
  white-space: nowrap;
  user-select: none;
}

.c-Button__Label {
  display: block;
  padding: (font-size-em(8) - 1) / 2;
}

.c-Button__Icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: font-size-em(5);
  height: font-size-em(5);
  font-size: font-size-em(3);
  vertical-align: middle;

  .c-Icon {
    display: block;
  }

  & + .c-Button__Label {
    border-color: rgba(255, 255, 255, 0.1);
    border-width: 1px;
    border-left-style: solid
  }
}


// Remove separator and gap between icon and label of non-primary buttons

.c-Button:not(.c-Button--Primary) {
  &:not(.c-Button--Flipped) {
    .c-Button__Icon {
      & + .c-Button__Label {
        padding-left: 0;
        border-left-style: none;
      }
    }
  }

  &.c-Button--Flipped {
    .c-Button__Icon {
      & + .c-Button__Label {
        padding-right: 0;
        border-right-style: none;
      }
    }
  }
}


// Primary
// -------

.c-Button--Primary {
  border-color: rgba(255, 255, 255, 0.4);
}


// Flipped icon / label position
// -----------------------------

.c-Button--Flipped {
  flex-direction: row-reverse;

  .c-Button__Icon {
    & + .c-Button__Label {
      border-left-style: none;
      border-right-style: solid;
    }
  }
}


// Disabled state
// --------------

.c-Button:disabled {
  cursor: not-allowed;
  opacity: 0.25;
}


// On / off states (switch mode)
// -----------------------------

// .c-Button--Off {}

// .c-Button--On {}


// Buttons attached to input

.c-Button--Annexed {
  font-size: font-size-em(-2);
}
