@import "src/styles/theme";

.c-TextArea {
  @include interactive-input;
  @include color("gray", $shade: -1);

  padding: space-rem(.5);
  font-size: font-size-rem(0);
  width: 100%;
}
