@import "src/styles/theme";

.c-Field {
  & + & {
    margin-top: space-rem(1);
  }
}

// .c-Field__LabelContainer {}

.c-Field__Label {
  @include title(1);

  display: block;
  margin-bottom: .4em;
  color: color($contrast: -3);
}

.c-Field__Input {
  display: flex;
  align-items: center;
}

.c-Field__Hint {
  margin-top: .4em;
  font-size: font-size-em(-2);
  color: color($contrast: -3);
  padding-left: calc((#{font-size-rem(5)} - 1rem) / 2)
}

// Group of Fields
// ---------------

.c-Field + .c-Field,
.c-Group + .c-Field {
  margin-top: space-rem(1);
}


// Horizontal layout
// -----------------

.c-Field--Horizontal {
  .c-Field__LabelContainer {
    display: flex;
    align-items: center;
  }

  .c-Field__Label {
    min-width: .5em;
    margin-bottom: 0;
    margin-right: space-rem(.5);
  }
  .c-Field__Input {
    .c-Input {
      min-width: 0;
      width: 100%;
    }
  }
}


// Highlighted
// -----------

.c-Field--Highlighted {
  .c-Field__Label {
    color: color("green", $contrast: 1);
    font-weight: bold;
  }

  .c-Input {
    color: color("green", $contrast: 1);
  }
}
