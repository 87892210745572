@mixin interactive {
  cursor: pointer;

  .js-focus-visible &:focus:not(.focus-visible) {
    outline: none;
  }

  &.focus-visible {
    outline: 1px dotted color("blue", $shade: 4) !important;
  }

  &:hover {
    color: color("gray", $shade: 10);
    text-shadow: 0 0 10px transparentize(color("blue", $shade: 4), .8);
  }

  &:active {
    color: color("green", $shade: 10);
  }
}

@mixin interactive-input {
  &:focus {
    outline: 2px solid color("blue", $shade: 4);
    box-shadow: 0 0 10px transparentize(color("blue", $shade: 4), .8);
  }
}
