@import "src/styles/theme";

.c-Input {
  @include interactive-input;
  @include color($shade: -1, $contrast: 2);

  padding: calc((#{font-size-em(5)} - 1em) / 2);
  width: 100%;
  border: 1px solid transparent;
}

// Disabled

.c-Input:disabled {
  color: color($shade: -1, $contrast: -2);
  cursor: not-allowed;
}

// Transparent

.c-Input--Transparent {
  background-color: transparent;
  padding-left: 0;
  padding-right: 0;
}

// With Scrubber

.c-Input--WithScrubber {

  &:not(:disabled) {
    cursor: ew-resize;
  }
}
