@import "src/styles/theme";

// .c-ColorRamp {}

.c-ColorRamp__Item {
  padding-top: space-rem(1);
  padding-left: space-rem(.5);
  padding-right: space-rem(.5);
}

.c-ColorRamp__Head {
  width: 9rem;
  min-width: 9rem;
  padding-top: space-rem(1);
  vertical-align: top;
  color: var(--fg-color);
}

.c-ColorRamp__HeadHeader {
  display: flex;
  width: 100%;
  align-items: center;
}

.c-ColorRamp__Label {
  @include title(1);

  flex: 1 0 60%;
  text-align: left;
}

.c-ColorRamp__Delete {
  flex: 0 0 auto;
}

// Gapless
// -------

.c-ColorRamp--Gapless {
  .c-ColorRamp__Item {
    padding-left: 0;
    padding-right: 0;
  }

  .c-ColorRamp__Head {
    padding-right: space-rem(1);
  }
}
