@import "../atoms/all";
@import "../style-components/all";

@include token(
  "shade",
  (
    "base": 3,
    "inverse-base": 1,
    "contrast": 7,
    "inverse": false
  )
);

@include token(
  "color",
  (
    "gray":  (#000000, #131313,#262626,#393939,#4e4e4e,#636363,#797979,#919191,#a8a8a8,#c1c1c1,#dadada,#f3f3f3,#ffffff),
    "blue":  (#000000, #060e3a,#101e62,#1b2e8b,#2740b8,#3653e3,#3977de,#4294e8,#59aff3,#85c9f6,#bddefa,#ecf5fd,#ffffff),
    "red":   (#000000, #2e0304,#50090c,#731116,#981a21,#bf242c,#e82d37,#f35f63,#f58a8c,#f7aeb0,#fad0d1,#fdf1f1,#ffffff),
    "green": (#000000, #121503,#242808,#363d10,#485219,#5b6922,#6e812c,#819a36,#94b440,#c6c74a,#fad855,#fdf2e7,#ffffff)
  )
);

@include token(
  "type-scale",
  (
    "base-font-size": 16,
    "ratio": 1.125,
    "base-label-index": 9,
    "labels": yocto zepto atto femto pico nano micro milli uno kilo mega giga
      tera peta exa zetta yotta bronto gego
  )
);

@include token(
  "line-height",
  (
    "body": 1.6,
    "heading": 1.2
  )
);

@include token(
  "line-length",
  (
    "default": 35
  )
);

@include token(
  "font-family",
  (
    default: ("Source Code Pro", monospace),
    monospace: ("Source Code Pro", monospace)
  )
);

@include token(
  "space-unit",
  (
    "relative": font-size-relative(0),
    "static": font-size-px(0)
  )
);
