@import "src/styles/theme";

.c-ColorSample {
  @include aspect-ratio(1/1);

  width: 100%;
  background-color: color($shade: -1);
}

.c-ColorSample__Content {
  overflow: auto;
  padding: space-rem(1);
}
