@mixin aspect-ratio($ratio: 1) {
  position: relative;
  height: auto;

  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 0;
    padding: 0;
    padding-top: $ratio * 100%;
  }

  > * {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
