@import "src/styles/theme";

.c-Section {
  padding: space-rem(1);
}

// Separated
// ---------

.c-Section--Separated {
  border-top: 1px solid color("gray", $shade: -1);
}
