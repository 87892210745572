@import url('https://fonts.googleapis.com/css?family=Source+Code+Pro:400,700&display=swap&subset=latin-ext');

@import "reset";
@import "normalize";
@import "../theme.scss";

// Fill available vertical space

html,
body {
  height: 100%;
  background-color: black;
}

// Typography

html {
  font-family: token("font-family", "default");
  font-size: font-size-px(0);
}

input, textarea {
  font-family: token("font-family", "monospace");
}

::selection {
  @include color(
    $bg: "blue",
    $fg: "gray",
    $shade: 3,
    $bg-transparency: .01
  );
}
