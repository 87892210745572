@import 'src/styles/theme.scss';

.c-Panel {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  min-width: 360px;
  overflow: hidden;
}

.c-Panel__Content {
  @include color('gray');

  flex-grow: 1;
  overflow-y: auto;
  position: relative;
}

.c-Panel__Header,
.c-Panel__Footer {
  $padding: space-rem(1);
  $height: $padding * 2 + font-size-rem(-2);

  @include color('gray', $shade: -1, $contrast: -2);

  display: flex;
  align-items: center;
  flex: 0 0 $height;
  padding: $padding;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: $height - font-size-rem('yotta');
  font-size: font-size-rem(-2);
}

.c-Panel__Title {
  @include title($level: 1, $line-height: 1);

  flex-grow: 1;

  strong {
    color: color($shade: -1, $contrast: 1);
  }
}

.c-Panel__Toolbar {
  display: flex;
  flex-wrap: nowrap;
}

// Shrunk
// ------

.c-Panel--Shrunk {
  flex-grow: 0;
  flex-shrink: 1;
}

// Floated
// -------

.c-Panel--Floated {
  flex-grow: 0;
  border: 1px solid color("gray", $shade: 0);

  .c-Panel__Footer {
    justify-content: flex-end;
  }
}
