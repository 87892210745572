@import "src/styles/theme";

.c-Modal {
  @include bg-polka-dots($bgcolor: rgba(0, 0, 0, .8), $transparency: .5, $dark: true, $bgsize: 6px, $dotsize1: 45%, $dotsize2: 45%);

  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 500;
}
