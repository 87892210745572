@import "src/styles/theme";


@mixin underline($color) {
  &::before {
    $thickness: 4px;
    $gap: 2px;
    $border-correction: 1px;

    content: "";
    display: block;
    position: absolute;
    bottom: ($thickness + $gap + $border-correction) * -1;
    left: $border-correction * -1;
    right: $border-correction * -1;
    height: $thickness;
    background-color: $color;
  }
}

.c-Swatch {
  min-width: space-rem(6);
  width: space-rem(6);
  font-size: font-size-em(-2);
  color: color($contrast: -2);
}

.c-Swatch__Color {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: space-rem(6);
  border: 1px solid transparent;
  font-size: font-size-rem(0);
}

.c-Swatch__Values {
  margin-top: space-rem(.5);
}

.c-Swatch__Value {
  &:not(:last-child) {
    margin-bottom: 2px;
  }
}

// FixWidth
// --------
.c-Swatch--FixWidth {
  width: space-rem(6);
}

// Interactive
// -----------
.c-Swatch--Interactive {
  .c-Swatch__Color {
    cursor: pointer;

    &:focus {
      outline: none;
    }

    &:hover {
      @include underline(transparentize(color("green", $contrast: 1), .5));
    }
  }
}


// Selected
// --------
.c-Swatch--Selected {
  &,
  &:hover {
    .c-Swatch__Color {
      @include underline(color("green", $contrast: 1));
    }
  }
}


// With white strip
// ----------------
.c-Swatch--WithWhiteBand {
  .c-Swatch__Color::after {
    content: "";
    display: block;
    position: absolute;
    bottom: -1px;
    left: -1px;
    right: -1px;
    height: space-rem(1);
    background-color: white;
  }
}


// Grayscale mode
// --------------
.c-Swatch--Grayscale {
  .c-Swatch__Color {
    filter: grayscale(1);
  }
}
