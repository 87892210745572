@import "src/styles/theme";

.c-Group {
  display: flex;
  justify-content: space-between;
}

.c-Group__Item {
  flex-grow: 1;

  &:not(:first-child) {
    margin-left: space-rem(1);
  }
}
