@import 'src/styles/theme';

.c-ColorPalette {
  --fg-color: #{color($shade: -2, $contrast: 0)};

  table-layout: auto;
  user-select: none;
}

.c-ColorPalette__Header {
  @include title(1);

  color: var(--fg-color);
}

// .c-ColorPalette__RampHeader { }

.c-ColorPalette__ShadeHeader {
  padding-left: space-rem(0.5);
  padding-right: space-rem(0.5);
  text-align: center;
}

.c-ColorPalette--Gapless {
  .c-ColorPalette__RampHeader,
  .c-ColorPalette__ShadeHeader {
    padding-left: 0;
    padding-right: 0;
  }
}

// Sticky headers
// --------------

.c-ColorPalette--StickyHeaders {
  .c-ColorPalette__RampHeader {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 2;
  }

  .c-ColorPalette__ShadeHeader {
    position: sticky;
    top: 0;
    z-index: 1;
    padding-top: space-rem(1);
  }

  // Managed ColorRamp
  .c-ColorRamp__Head {
    position: sticky;
    left: 0;
    background-color: black;
    z-index: 1;
    padding-left: space-rem(1);
  }
}
