@import "./reverse";

@function color(
  $name: "gray",
  $shade: 0,
  $contrast: null,
  $inverse: false,
  $transparency: 0
) {
  $global-inverse: token("shade", "inverse");
  $inverse: if($inverse == $global-inverse, false, true);

  $ramp: if($inverse, reverse(token("color", $name)), token("color", $name));
  $base: if($inverse, token("shade", "inverse-base"), token("shade", "base"));

  $enhancement: if($contrast, token("shade", "contrast") + $contrast, 0);
  $position: $base + $shade + $enhancement;

  @if $position < 1 { $position: 1 }
  @if $position > length($ramp) { $position: length($ramp) }

  $color: nth($ramp, $position);

  @return if($transparency > 0, transparentize($color, $transparency), $color);
}

// Returns a pair of background / foreground color in the specified contrast.
@mixin color(
  $bg: "gray",
  $shade: 0,
  $fg: $bg,
  $contrast: 0,
  $inverse: false,
  $bg-transparency: 0,
  $fg-transparency: 0
) {
  background-color: color($bg, $shade, $inverse: $inverse, $transparency: $bg-transparency);
  color: color($fg, $shade, $contrast, $inverse: $inverse, $transparency: $fg-transparency);
}
