@import "src/styles/theme";

@include token(
  "c-Text", (
    "indent": space-em(1.5),
    "breakpoint": token("line-length", "default") * 1em + space-em(4),
    "heading-sizes": (8, 6, 4, 2, 0, -2),
    "heading-all-caps-indexes": 6,
    "blockquote-line-width": 2px
  )
);

@mixin article-title($level) {
  $sizes: token("c-Text", "heading-sizes");
  $all-caps-indexes: token("c-Text", "heading-all-caps-indexes");

  @include title(
    $level,
    $sizes: $sizes,
    $all-caps-indexes: $all-caps-indexes,
    $em: true
  );

  margin-top: 1.6em;
}

@mixin bullets(
  $content: "•",
  $indent: 1.5em,
  $font-size-steps: 0,
  $color: currentColor,
  $hanging-from: null
) {
  $compensation: font-size-relative($font-size-steps * -1);

  padding-left: $indent;

  li {
    position: relative;

    &::before {
      content: $content;
      position: absolute;
      left: $indent * $compensation * -1;
      color: $color;
      font-size: font-size-em($font-size-steps);
      font-variant-numeric: tabular-nums;
      line-height: token("line-height", "body") * $compensation;
    }
  }
}

@mixin hanging-bullets($indent: 1.5em, $bullet-distance: 1.1em) {
  margin-left: $indent * -1;

  ul, ol {
    margin-left: 0;
  }

  & > li::before {
    $width: 1.5em;

    width: $width;
    left: ($width + $bullet-distance) * -1;
    text-align: right;
  }
}

// Main element
// ------------

.c-Text {
  --color-heading: #{color($contrast: 2)};
  --color-bullet:  #{color("blue", $contrast: -2)};
  --color-strong: #{color("gray", $contrast: 2)};
  --color-del: #{color("red", $contrast: -2)};
  --color-ins: #{color("green", $contrast: 0)};
  --color-mark-bg: #{color("green", $inverse: true, $shade: 2, $transparency: .01)};
  --color-mark-fg: #{color("gray", $inverse: true, $shade: 1, $contrast: 0)};
  --color-link-underline: #{color("blue", $contrast: -5)};
  --color-link-underline-hover: #{color("blue", $contrast: 0)};
  --color-link: #{color("gray", $contrast: 2)};
  --color-hr: #{color($contrast: -5)};
  --color-footnote-link: #{color($contrast: -3)};
  --color-footnote-link-hover: #{color("blue", $contrast: 0)};
  --color-code: #{color("blue", $contrast: 0)};
  --color-pre: #{color($contrast: -6)};
  --color-table-border: #{color($contrast: -5)};
  --color-table-head: #{color($contrast: -2)};
  --color-dt: #{color($contrast: 2)};

  width: 100%;
  line-height: token("line-height", "body");

  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }

  p, pre, table, blockquote {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  p {
    font-variant-numeric: oldstyle-nums;
  }

  h1, h2, h3, h4, h5, h6 {
    & + * {
      margin-top: .35em;
    }

    & + dl {
      margin-top: 1em;
    }
  }

  // Headings
  // --------

  @for $level from 1 through 6 {
    h#{$level} {
      @include article-title($level);

      color: var(--color-heading);
    }
  }

  // Lists
  // -----

  ul {
    @include bullets(
      $indent: token("c-Text", "indent"),
      $color: var(--color-bullet),
    );
  }

  ol {
    counter-reset: c-text-ol;

    li {
      counter-increment: c-text-ol;
    }

    @include bullets(
      $content: counter(c-text-ol),
      $indent: token("c-Text", "indent"),
      $color: var(--color-bullet),
      $font-size-steps: -1,
    );
  }

  strong {
    font-weight: bold;
    color: var(--color-strong);
  }

  em {
    font-style: italic;
  }

  del {
    color: var(--color-del);
    text-decoration: line-through var(--color-del);
  }

  ins {
    color: var(--color-ins);
    text-decoration: none;
  }

  mark {
    background-color: var(--color-mark-bg);
    color: var(--color-mark-fg);
  }

  abbr {
    text-decoration: none;
    border-bottom: 1px dashed var(--color-link-underline);
    cursor: help;

    &:hover {
      border-bottom-color: var(--color-link-underline-hover);
    }
  }

  sup, sub {
    font-size: font-size-em(-2);
  }

  hr {
    margin: 3em 0;
    border: none;
    border-top: 2px solid var(--color-hr);
  }

  a {
    border-bottom: 2px solid var(--color-link-underline);
    color: var(--color-link);
    text-decoration: none;

    &:hover {
      border-bottom-color: var(--color-link-underline-hover);
    }
  }

  sup a {
    border: none;
    color: var(--color-footnote-link);

    &:hover {
      color: var(--color-footnote-link-hover);
    }
  }

  blockquote {
    $line-width: token("c-Text", "blockquote-line-width");

    padding-left: token("c-Text", "indent");
    border-left: $line-width solid var(--color-bullet);

  }

  code {
    color: var(--color-code);
    font-family: token("font-family", "monospace");
  }

  pre {
    padding: 1em;
    border: 2px solid var(--color-pre);
    overflow-y: scroll;
  }

  img {
    width: 100%;
  }

  table {
    width: 100%;
  }

  td, th {
    padding: 1em;
    border-bottom: 1px solid var(--color-table-border);
    font-size: font-size-em(-1);
    line-height: token("line-height", "heading");
    text-align: left;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  td:not(:last-child) {
    border-right: 1px dashed var(--color-table-border);
  }

  th {
    @include title(1);

    border-bottom: 2px solid var(--color-table-border);
    color: var(--color-table-head);
  }

  dt {
    @include title(1);

    color: var(--color-dt);

    & + dd {
      margin-top: .3em;
    }
  }

  dd {
    &:not(:last-child) {
      margin-bottom: 1em;
    }

    & > p:first-child {
      margin-top: 0;
    }

    & > p:last-child {
      margin-bottom: 0;
    }
  }
}


// Colors
// ------

.c-Text--Mono {
  --color-heading: currentColor;
  --color-bullet: currentColor;
  --color-strong: currentColor;
  --color-del: currentColor;
  --color-ins: currentColor;
  --color-mark-bg: transparent;
  --color-mark-fg: currentColor;
  --color-link-underline: currentColor;
  --color-link-underline-hover: currentColor;
  --color-link: currentColor;
  --color-hr: currentColor;
  --color-footnote-link: currentColor;
  --color-footnote-link-hover: currentColor;
  --color-code: currentColor;
  --color-pre: currentColor;
  --color-table-border: currentColor;
  --color-table-head: currentColor;
  --color-dt: currentColor;
}


// Hanging bullets
// ---------------

.c-Text--HangingBullets {

  @include break($from: token("c-Text", "breakpoint")) {
    $line-width: token("c-Text", "blockquote-line-width");

    & > ul {
      @include hanging-bullets($indent: token("c-Text", "indent"));
    }

    & > ol {
      @include hanging-bullets(
        $indent: token("c-Text", "indent")
      );
    }

    & > blockquote {
      margin-left: calc((#{token("c-Text", "indent")} + #{$line-width}) * -1);
    }
  }
}
