@mixin title(
  $level: 2,
  $line-height: token("line-height", "heading"),
  $sizes: (-2, 0, 2, 5, 8),
  $all-caps-indexes: 1,
  $em: false
) {
  @if $level > length($sizes) {
    $level: length($sizes);
  }

  @if index($all-caps-indexes, $level) != null {
    @include all-caps;
  }

  font-size: if($em, font-size-em(nth($sizes, $level)), font-size-rem(nth($sizes, $level)));
  font-weight: bold;
  line-height: $line-height;
  text-rendering: optimizelegibility;
}
