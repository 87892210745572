.c-PanelManager {
  display: flex;
  height: 100vh;
  padding: 2px;
}

// Content
// -------

.c-PanelManager > * {
  &:not(:first-child) {
    margin-left: 2px;
  }
}
